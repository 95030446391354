import React, {useState} from 'react';
import { Error } from '../ui/Error';

import Swal from 'sweetalert2';

export const FormTransferencia = ( {bancos, cobrado, setCobrado, clienteSelect} ) => {

    //console.log(bancos);

    //State para mostrar el error
    const [error, setError] = useState(false);

    const [transferencia, setTransferencia] = useState({
        idmCobro: 2,
        medioCobro: 'Transferencia',
        idcliente: clienteSelect,
        idbanco: '',
        fecha: '',
        numero: '',
        valor: ''
    });

    const {idbanco, fecha, numero, valor} = transferencia; 

    const handleChange = ( e ) => {
        setTransferencia({
            ...transferencia,
            [ e.target.name ] : e.target.value
        })
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();

        //console.log(transferencia);
        if ( parseInt(valor) > 0) {

            setCobrado([
                ...cobrado ,
                transferencia
            ])
    
                setTransferencia({
                    idmCobro: 2,
                    medioCobro: 'Transferencia',
                    idcliente: clienteSelect,
                    idbanco: '',
                    fecha: '',
                    numero: '',
                    valor: ''
                }) 

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Cobro Agregado',
                    showConfirmButton: false,
                    timer: 1500
                  })

        } else {
            
            setError(true);           

            setTimeout(() => {
                setError(false)
            }, 2000);
            
            }
        }        

    return (
    
        <div className="container">
            <div className="row">   

                { error && <Error mensaje="Por favor ingrese un importe mayor a cero." /> }

                <form onSubmit={ handleSubmit }>                    
                
                <label className="form-label mt-3">Transferencia:</label>
                    <div className="row">    

                        <div className="col-3">                            
                            <label className="form-label"> Fecha:</label>
                            <input 
                                type="date" 
                                name="fecha"
                                value={ fecha }
                                className="form-control"                            
                                onChange={ handleChange }
                                required                            
                            />
                        </div>

                        <div className="col-3">                            
                            <label className="form-label"> Numero:</label>
                            <input 
                                type="number" 
                                name="numero"
                                value={ numero }
                                className="form-control"                            
                                onChange={ handleChange }           
                            />
                        </div>

                        <div className="col-4">                            
                            <label className="form-label"> Banco:</label>
                            
                            <select 
                                name="idbanco"
                                className="form-select"                                
                                onChange={ handleChange }
                                value={ idbanco }
                                required
                            >
                                <option defaultValue> -- Bancos -- </option>
                                {/* <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option> */}
                                {
                                    bancos.map( (banco) => (
                                         <option 
                                            key={banco.idbanco} 
                                            value={ banco.idbanco }
                                        >{banco.codbanco} - {banco.codagencia} - {banco.codcuenta} - {banco.nombrebanco}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="col-2">                            
                            <label className="form-label"> Importe $:</label>
                            
                            <input 
                                type="number" 
                                name="valor"
                                maxLength="12"
                                className="form-control"
                                placeholder="Importe..."  
                                autoComplete="off"                               
                                onChange={ handleChange }
                                value={ valor }
                                required
                            />
                        </div>

                    </div>        
                        <button type="submit" className="btn btn-success mt-3 btn-add-cobranza"> 
                                {`AGREGAR  `}
                                <i className="bi bi-box-arrow-down"></i> 
                        </button>                      
                </form>                                
            </div>
        </div>
    )
}
