import axios from 'axios';
import config from '../config.json';

export const getClientes = async ( idvendedor ) => {

    let aux = {};

    const url = `${config.SERVER_URL}/gets/getClientesPorVendedor.php?idvendedor=${idvendedor}`;

    //console.log(url);

    await axios.get(url).then( res => {
        aux = res.data;
   } );

   return aux;

}
