import axios from 'axios';
import config from '../config.json';

export const getFacturas = async ( clienteId, empresaId ) => {
    
    let aux = {};

    const url = `${config.SERVER_URL}/gets/getFacturasPorCliente.php?idcliente=${clienteId}&empresa=${empresaId}`;
  
    await axios.get(url).then( res => {
        aux = res.data;
   } );

   //console.log(url);
   //console.log(aux);

   return aux;

}
