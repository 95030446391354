import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import 'animate.css';
import axios from 'axios';
import config from '../../config.json';
import Swal from 'sweetalert2';

export const ResetPasswordScreen = ( {history} ) => {

    const [usuario, setUsuario] = useState({
        correo: '',
        passwordAct: '',
        passwordNew: '',
        passwordNewRepeat: '',
    })

    const { correo, passwordAct, passwordNew, passwordNewRepeat } = usuario;

    const handleInputChange = (e) => {

        setUsuario({ ...usuario,
            [ e.target.name ] : e.target.value
        })
        //console.log(usuario);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        console.log(usuario);

        // Convertir el password a base 64
        const passwordActB64 = Buffer.from(passwordAct).toString('base64');
        const passwordNewB64 = Buffer.from(passwordNew).toString('base64');

        //Realizo el cambio de contraseña
        const url = `${config.SERVER_URL}/sets/setUsuario.php?codigo=${correo}&pass=${passwordActB64}&newpass=${passwordNewB64}`;

        const result = await axios.get(url)
        //console.log(result);

        //Desestructuro la respuesta
        const { data: {estado, observaciones} } = result;
        console.log(estado);
        console.log(observaciones);

        if ( !estado ) {

            //Mostrar el mensaje de error
            Swal.fire({
                icon: 'error',
                title: `${observaciones}`,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              })
        
        } else {

            Swal.fire({
                icon: 'success',
                title: `Se actualizo la contraseña correctamente.`,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              })

            setTimeout(() => {
                //Redireccion a la pagina de recibos                   
                history.push('/login');          
            }, 1500);

        }

    }

    return (
                <>      

                <div role="main" className="container my-auto">
                <div className="row mt-5">
                    <div id="login" className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12">
                            {/*<img className="img-fluid mx-auto d-block rounded animate__animated animate__zoomIn"
                            src={ require('../../images/logo.png') } alt="logo" />*/}
                                {/* src="https://picsum.photos/id/870/300/200" /> */}
                            
                            {/* { error && <Error mensaje="Por favor revise el usuario y contraseña ingresados." />} */}
                            
                            <form onSubmit={ handleSubmit } className="animate__animated animate__fadeIn">
                                <div className="form-group">

                                    <i className="bi bi-person-check" width="30px"></i>
                                    <label htmlFor="correo"> ` Ingrese el usuario:`</label>
                                    
                                    <input 
                                        className="form-control"                                        
                                        name="correo"                                            
                                        type="text"                                           
                                        autoComplete="off"
                                        // placeholder="Ingrese su usuario o correo electronico..."                                     
                                        onChange={ handleInputChange } 
                                        value={correo}                                   
                                    />                           

                                </div>

                                <div className="form-group mt-3">
                                    
                                    <i className="bi bi-key-fill"></i>
                                    <label htmlFor="passwordAct">`  Ingrese la contraseña actual:`</label>
                                    <input 
                                        className="form-control"                                      
                                        name="passwordAct"                                            
                                        type="password"
                                        // placeholder="Ingrese su contraseña..." 
                                        onChange={ handleInputChange }
                                        value={passwordAct} 
                                    />

                                </div>

                                <div className="form-group mt-3">
                                    
                                    <i className="bi bi-key-fill"></i>
                                    <label htmlFor="passwordNew">`  Ingrese la contraseña nueva:`</label>
                                    <input 
                                        className="form-control"                                      
                                        name="passwordNew"                                            
                                        type="password"
                                        // placeholder="Ingrese su contraseña..." 
                                        onChange={ handleInputChange }
                                        value={passwordNew} 
                                    />

                                </div>

                                <div className="form-group mt-3">
                                    
                                    <i className="bi bi-key-fill"></i>
                                    <label htmlFor="passwordNewRepeat">`  Repetir la contraseña nueva:`</label>
                                    <input 
                                        className="form-control"                                      
                                        name="passwordNewRepeat"                                            
                                        type="password"
                                        // placeholder="Ingrese su contraseña..." 
                                        onChange={ handleInputChange }
                                        value={passwordNewRepeat} 
                                    />

                                </div>

                                <div className="form-group"> 
                                    <div className="row m-3">
                                        <button 
                                            type="submit"                                   
                                            className="btn btn-login mt-3 p-1 animate__animated animate__bounceInUp"                                        
                                            disabled={ correo < 1 || passwordAct < 1 || passwordNew < 1 || passwordNewRepeat < 1 || (passwordNewRepeat !== passwordNew)}
                                        >                                                              

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-open" viewBox="0 0 16 16">
                                            <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                                            <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
                                        </svg>

                                            {` GUARDAR`}
                                        </button>   
                                    </div>

                                    <div className="row m-3">
                                        <Link                         
                                            to="/login"   
                                                        
                                        >
                                            <button 
                                                    type="submit"                                   
                                                    className="btn btn-login mt-3 p-1 animate__animated animate__bounceInUp"                                                                                            
                                                >                                                              

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace" viewBox="0 0 16 16">
                                                <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"/>
                                                <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z"/>
                                                </svg>

                                                    {` VOLVER`}
                                                </button> 

                                        </Link>  
                                    </div>
                                </div>                                                       
                            </form>                                      
                    </div>   
                </div>        
            </div>               
        </> 

    )
}
