import React from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import 'animate.css';

import config from '../../config.json';

import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

import { BsFillEyeFill } from "react-icons/bs";

export const TablaRecibos = ( {recibosDB, setRecibosDB, history} ) => {

    // const [columns, setColumns] = useState([
    //     { title: 'Recibo Id', field: 'idrecibo'},
    //     { title: 'Fecha', field: 'fecha' },
    //     { title: 'Razon Social', field: 'razonsoc' },
    //     { title: 'Total', field: 'total'},
    //     { title: 'Estado.', field: 'estado' }
    // ]);

    const handleVisualizar = ( rowData ) => {

        console.log('Visualizando recibo ...');
        console.log(rowData)
    }

    const columns = React.useMemo ( () => [ 
      
                                            { title: 'Recibo Id', field: 'idrecibo', cellStyle: { textAlign: 'center'}},
                                            { title: 'Fecha', field: 'fecha', cellStyle: { textAlign: 'center'} },
                                            { title: 'Razon Social', field: 'razonsoc', cellStyle: { textAlign: 'left'} },
                                            { title: 'Total', field: 'total', cellStyle: { textAlign: 'right'}},
                                            { title: 'Estado', field: 'estado', cellStyle: { textAlign: 'center'} },

                                                    // rowData && (
                                                    // <IconButton
                                                    //     color="secondary"
                                                    //     onClick={() => addActionRef.current.click()}
                                                    // >
                                                    //     <AddIcon />
                                                    // </IconButton>
                                                    //)
                                            {                                        
                                                field: "internal_action",  
                                                cellStyle: { textAlign: 'right' },                                                     
                                                render: ( rowData ) => 
                                                    
                                                    rowData && (
                                                        
                                                        <Link to={ `./recibo/${ rowData.idrecibo }` } >
                                                            <BsFillEyeFill />
                                                        </Link>
    
                                                    )
    
                                                },  
                                                                                      
                                        ], [] );

    return (
        <div>
            <div className="container-md animate__animated animate__fadeInDown">
            
                <div style={{ maxWidth: '100%', fontFamily: 'sans-serif', alignItems: 'center', marginBottom:'50px'}}>
      
                    <MaterialTable

                        columns={columns}
                        data={recibosDB}
                        title="RECIBOS"
                        options={{    
                            headerStyle: {
                                backgroundColor: '#01579b',
                                color: '#FFF',
                                position: 'sticky',
                                padding: '7px',
                                textAlign: 'center',                                                          
                            },
                            cellStyle: {
                                width: 20,
                                maxWidth: 20
                              },
                            actionsColumnIndex: -1,
                        }}       

                        // onRowClick={((evt, selectedRow) => {console.log(selectedRow) 
                        
                        //     setTimeout(() => {
                                
                        //         <Link to="./recibo/22" > </Link>

                        //     }, 2);
                        
                        // })}

                        localization={{
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'No se encontraron resultados...',
                                editRow : { deleteText: 'Esta seguro que desea eliminar el recibo seleccionado?' }
                            },
                            toolbar: {
                                searchPlaceholder: 'Busqueda...'
                            },
                            pagination: {
                                labelRowsSelect: 'Filas'
                            }
                        }}

                        editable={{
                            isDeletable: rowData => rowData.estado === 'Pendiente', // only name(b) rows would be deletable,                           
                            // onRowDelete: oldData =>
                            //     new Promise((resolve, reject) => {
                            //         setTimeout(() => {
                                        
                            //             console.log('Eliminar recibo...');
                            //             console.log(oldData);

                            //             const url = `${config.SERVER_URL}/sets/setBorrado.php?idrecibo=${oldData.idrecibo}`
                                        
                            //              const dataDelete = [...recibosDB];
                            //              const index = oldData.tableData.id;
                            //              dataDelete.splice(index, 1);
                            //              setRecibosDB([...dataDelete]);       
                                        
                            //             // axios.get(url)
                            //             //       .then(resp => console.log(resp))
                            //             //       .catch(err => console.log(err))                    
                            //             resolve();
                            //         }, 1000);
                            //     })
                        }}

                        actions={[
                            
                            {

                              icon: 'forward',
                              tooltip: 'Confirmar recibo',
                              onClick: (event, rowData) => {

                                    if (rowData.estado !== 'Confirmado' && rowData.estado !== 'Enviado' )
                                    {
                                        // Do save operation
                                        console.log('Confirmando recibo...', rowData);
                                                                

                                            Swal.fire({
                                                title: 'Confirmar recibo',
                                                text: "Esta seguro que desea confirmar el recibo? No sera posible revertir la confirmación.",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'ACEPTAR',
                                                cancelButtonText: 'CANCELAR',
                                                footer: '<strong>Cremigal Cobranzas</strong>'

                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    
                                                    //Actualizo el estado en el state
                                                    rowData.estado = 'Confirmado';

                                                    const dataUpdate = [...recibosDB];
                                                    const index = rowData.tableData.id;
                                                    dataUpdate[index] = rowData;
                                                    setRecibosDB(dataUpdate);
                                                    
                                                    axios.get( `${config.SERVER_URL}/sets/setConfirmacion.php?idrecibo=${rowData.idrecibo}`)
                                                    .then( resp => console.log(resp) )
                                                    .catch( err => console.log(err) );

                                                    Swal.fire(
                                                        'Recibo confirmado!',
                                                        'El recibo seleccionado fue confirmado con exito.',
                                                        'success'
                                                )
                                                }
                                            })
                                    }else {

                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error al intentar confirmar recibo',
                                            text: 'No es posible confirmar un recibo en estado Confirmado o Enviado...',
                                            confirmButtonText: 'ACEPTAR',
                                            footer: '<strong>Cremigal Cobranzas</strong>'
                                          })


                                    }

                                } 
                            },

                            {
                                
                                icon: 'delete',
                                tooltip: 'Eliminar recibo',
                                onClick: (event, rowData) => {
                                  // Do save operation
                                  console.log('Eliminando recibo...')

                                  //console.log(rowData);

                                  if ( rowData.estado !== 'Pendiente' ) {

                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error al intentar eliminar',
                                        text: 'No es posible eliminar un recibo en estado distinto de Pendiente...',
                                        confirmButtonText: 'ACEPTAR',
                                        footer: '<strong>Cremigal Cobranzas</strong>'
                                      })

                                   } else {
                                    
                                        const url = `${config.SERVER_URL}/sets/setBorrado.php?idrecibo=${rowData.idrecibo}`
                                                                                 
                                        Swal.fire({
                                            title: 'Eliminar recibo',
                                            text: "Esta seguro que desea eliminar el recibo? No sera posible revertir el borrado",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'ACEPTAR',
                                            cancelButtonText: 'CANCELAR',
                                            footer: '<strong>Cremigal Cobranzas</strong>'

                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                
                                                //Actualizo el state
                                                const dataDelete = [...recibosDB];
                                                const index = rowData.tableData.id;
                                                dataDelete.splice(index, 1);
                                                setRecibosDB([...dataDelete]);   
                                                
                                                //Borro la BD
                                                axios.get(url)
                                                .then(resp => console.log(resp))
                                                .catch(err => console.log(err))

                                            Swal.fire(
                                                'Recibo eliminado!',
                                                'El recibo seleccionado fue eliminado con exito.',
                                                'success'
                                            )
                                            }
                                        })
                                                                            
                                        //console.log('Eliminado...');

                                   } 
                                
                                } 

                            },                                        
                          ]}        
                          
                    />
                </div>
            </div>
        </div>
    )
}
