import React, { useContext } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import logo from '../../images/logo2.png';

export const NavBar = ( {history} ) => {

    const authContext = useContext(AuthContext);
    const { user, dispatch } = authContext;

    //console.log('Se renderizo el Navbar');

    const handleLogout = () => {
        
        const action = {
            type: types.logout
        }

        dispatch(action);

        // history.push('/login');
    }

    return (
        <nav className="navbar navbar-light bg-light">
            <div className="container-fluid">
                <span className="navbar-brand mb-0 h1">                       
                    <img id="logo2" src={ logo } alt="logo" />
                </span>
                    

                    <p className="nav-item nav-link text-info">
                            {user.nombre} / {user.empresa}
                    </p>

                    <ul className="navbar-nav ml-auto">                                    
                        <li>
                        <button 
                            className="nav-item nav-link btn" 
                             onClick={ handleLogout }
                        >
                            <p className="logout"> <i className="bi bi-door-closed-fill"></i> {`  Cerrar Sesion`} </p>
                            
                        </button>
                        </li>
                    </ul>
            </div>
        </nav>
    )
}
