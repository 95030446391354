import React, { useEffect, useMemo, useState} from 'react';
import MaterialTable from 'material-table';

export const TablaResumen = ( {cobrado, setCobrado} ) => {         

     //Columnas de la tabla
    //  const [columns, setColumns] =  useState([
    //      { title: 'Medio de Cobro', field: 'medioCobro' },
    //      { title: 'Banco', field: 'idbanco'},
    //      { title: 'Fecha', field: 'fecha'},
    //      { title: 'Vencto.', field: 'vencimiento'},
    //      { title: 'Numero', field: 'numero'},
    //      { title: 'Provincia', field: 'provincia'},
    //      { title: 'Banco Ch', field: 'bancoCh'},
    //      { title: 'Sucursal Ch', field: 'sucursalCh'},
    //      { title: 'Cuenta Ch', field: 'cuentaCh'},
    //      { title: 'CUIT Ch', field: 'cuitCh'},
    //      { title: 'Valor ($)', field: 'valor'}       
    // ]);

    const columns = useMemo( () => [ { title: 'Medio de Cobro', field: 'medioCobro' },
                                     { title: 'Banco', field: 'idbanco'},
                                     { title: 'Fecha', field: 'fecha'},
                                     { title: 'Vencto.', field: 'vencimiento'},
                                     { title: 'Numero', field: 'numero'},
                                     { title: 'Provincia', field: 'provincia'},
                                     { title: 'Banco Ch', field: 'bancoCh'},
                                     { title: 'Sucursal Ch', field: 'sucursalCh'},
                                     { title: 'Cuenta Ch', field: 'cuentaCh'},
                                     { title: 'CUIT Ch', field: 'cuitCh'},
                                     { title: 'Valor ($)', field: 'valor'} ], [])    

    const [total, setTotal] = useState(0)

    useEffect( () => {

        setTotal(0);

        //Totalizador
        cobrado.map( (c) => {

                setTotal( prevState => prevState + parseFloat(c.valor));

                return 0
        } ) 
    }, [cobrado]);

    

    return (
            <div className="container-md mt-3">
            
            <div style={{ maxWidth: '100%', fontFamily: 'sans-serif', alignItems: 'center', marginBottom:'50px'}}>               

                <span className="badge rounded-pill text-white mb-1 totales" style={{backgroundColor:'#FF5733'}}>
                         Total a cobrar: <span> ${ total.toFixed(2) }</span>
                </span>

                <hr />
                
                <MaterialTable
                    title="COBRANZAS"
                    columns={columns}
                    data={cobrado}
                    
                    options={{    
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            padding: '7px',  
                        },
                        // paging: false
                    }}

                    localization={{
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No se encontraron resultados...',
                            editRow : { deleteText: 'Esta seguro que desea eliminar el cobro ingresado?' }
                        },
                        toolbar: {
                            searchPlaceholder: 'Busqueda...'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas'
                        }
                    }}
                    
                    editable={{

                        onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...cobrado];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setCobrado([...dataDelete]);
                                
                                //console.log('Borrado...');

                                resolve();
                            }, 1000);
                         })
                        
                    }}
                    
                    // actions={[
                    //     {
                    //       icon: 'delete',
                    //       tooltip: 'Eliminar cobranza',
                     
                    //       onClick: (event, rowData) => {

                    //         const dataDelete = [...cobrado];
                    //         const index = rowData.tableData.id;
                    //         //console.log('rowData.tableData.id:',rowData.tableData.id)

                    //         // const dataDelete = cobrado.filter( (c, index) => (                                    
                    //         //          index !== rowData.tableData.id
                    //         //      ))                            
                    //         //console.log(dataDelete);
                    //          dataDelete.splice(index, 1)
                    //          setCobrado([...dataDelete])                            
                    //       }                        
                    //   }]}

                />
            </div>
        </div>
    )
}
