import React from 'react';

export const Error = ( {mensaje} ) => {

    const newText = mensaje.split('*').map(
        str => <span key="1">{str}<br /></span>
    );
    
    //console.log(mensaje);
    return (
        <div className="alert alert-danger mt-3 alerta">
               {newText}
        </div>
    )
}
