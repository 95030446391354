import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AltaReciboScreen } from '../components/recibos/AltaReciboScreen';
import { ReciboScreen } from '../components/recibos/ReciboScreen';
import { ReciboViewScreen } from '../components/recibos/ReciboViewScreen';
import { NavBar } from '../components/ui/NavBar';

export const DashboardRoutes = () => {
    return (
        
        <>           
            <NavBar />

            <div>
                <Switch>            
                    <Route  exact path="/altarecibo" component={ AltaReciboScreen } />
                    <Route  exact path="/recibo" component={ ReciboScreen } />
                    <Route  exact path="/recibo/:idrecibo" component={ ReciboViewScreen } />

                    <Redirect to="/recibo" />
                </Switch>
            </div>
        </>
    )
}
