import React, { useState, useContext } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import logo from '../../images/logo.png';
// import logo from '../../../public/logo.jpg';
import { getUsuario } from '../../selectors/getUsuario';
import { types } from '../../types/types';
import { Error } from '../../components/ui/Error';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import 'animate.css';

export const LoginScreen = ( {history} ) => {

  const [error, setError] = useState(false);

  const authContext = useContext(AuthContext);
  const { dispatch } = authContext;

  const [ usuarioForm, setUsuarioForm ] = useState({
    usuario: '',
    password: '',
    tipoUsuario: '01'
  });

    const { usuario, password, tipoUsuario } = usuarioForm;

    const handleInputChange = (e) => {

      setUsuarioForm({
        ...usuarioForm,
        [ e.target.name ]: e.target.value
      })
      //console.log(usuarioForm);
    }

    const handleLogin = async ( e ) => {

      e.preventDefault();

      // Convertir el password a base 64
      const paswordb64 = Buffer.from(password).toString('base64');

      // console.log('Ingreso en el login');
      // console.log(usuario);

      const usuarioDB =  await getUsuario( usuario, paswordb64 );
       //console.log('Pantalla:' ,JSON.stringify(usuarioDB));
      
      //valido si el usuario existe
      if (!usuarioDB.estado) {
        // console.log('No se encontro el usuario')        
        setError(true);
        
        setTimeout(() => {
          setError(false)
        }, 3000)
        
      } else {

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: 'Ingreso Exitoso...!'
        })

        // console.log('Se encontro el usuario');

        const usuario = {
            idvendedor: usuarioDB.idvendedor,
            tipoUsuario: tipoUsuario,
            nombre: usuarioDB.nombre,
            empresa: ( tipoUsuario === "01" ? "Cremigal" : "Capacitación" )
        }

        // console.log(usuario);

        const action = {
          type: types.login,
          payload: usuario
        }

        dispatch(action);

        history.push('/recibos');
      }
      
    }

    return (

      <>          
            <div role="main" className="container my-auto">
              <div className="row mt-5">
                  <div id="login" className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12">
                        <img className="img-fluid mx-auto d-block rounded animate__animated animate__zoomIn"
                        src={ logo } alt="logo" />
                            {/* src="https://picsum.photos/id/870/300/200" /> */}
                        
                        { error && <Error mensaje="Por favor revise el usuario y contraseña ingresados." />}
                        
                        <form onSubmit={ handleLogin } className="animate__animated animate__fadeIn">
                            <div className="form-group">

                                <i className="bi bi-person-check" width="30px"></i>
                                <label htmlFor="correo"> ` Usuario o email`</label>
                                
                                <input 
                                    className="form-control"                                        
                                    name="usuario"                                            
                                    type="text"                                           
                                    autoComplete="off"
                                    // placeholder="Ingrese su usuario o correo electronico..."                                     
                                    onChange={ handleInputChange }                                    
                                />                           

                            </div>

                            <div className="form-group mt-3">
                                
                                <i className="bi bi-key-fill"></i>
                                <label htmlFor="password">`  Contraseña`</label>
                                <input 
                                    className="form-control"                                      
                                    name="password"                                            
                                    type="password"
                                    // placeholder="Ingrese su contraseña..." 
                                    onChange={ handleInputChange }
                                />
                            </div>

                            <div className="form-check mt-3">
                              <input className="form-check-input" type="radio" name="tipoUsuario" value="01" onChange={ handleInputChange } defaultChecked />
                              <label className="form-check-label ml-3">
                                  Cremigal
                              </label>
                            </div>

                            <div className="form-check mt-3">
                              <input className="form-check-input" type="radio" name="tipoUsuario" value="02" onChange={ handleInputChange }/>
                              <label className="form-check-label ml-3">
                                  Capacitación
                              </label>
                            </div>

                            <div className="form-group mt-3 d-flex justify-content-center"> 
                                <button 
                                    type="submit"                                   
                                    className="btn btn-login mt-3 p-1 animate__animated animate__flip"                                        
                                    disabled={ usuario < 1 || password < 1 }
                                >                                                              

                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-open" viewBox="0 0 16 16">
                                    <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                                    <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
                                  </svg>

                                    {` INGRESAR`}
                                </button>   
                            </div> 

                            <div className="form-group mt-3 d-flex justify-content-right"> 
                                                           
                                <Link                         
                                    to="/login/reset"
                                    className="mt-3 link-dark"   
                                                       
                                >
                                    Restablecer contraseña...                                   
                                </Link>  

                              
                            </div> 



                        </form>                                      
                  </div>   
              </div>        
          </div>               
      </>     

        // <div className="min-h-screen flex items-center justify-center bg-white-100 py-12 px-4 sm:px-6 lg:px-8">
       
        // <div className="max-w-md w-full space-y-8">

        //   <div>
        //     <img className="mx-auto h-12 w-auto" src={ logo } alt="Logo" />
        //       <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        //           Gestion de Cobranzas
        //       </h2>
        //   </div>

        //   <form className="mt-8 space-y-6" onSubmit={ handleLogin }>
        //     <div className="rounded-md shadow-sm -space-y-px">
        //         <div>
        //           {/* <label htmlFor="email-address" className="sr-only">Usuario o email</label> */}
        //           <input 
        //                   id="usuario" 
        //                   name="usuario" 
        //                   type="text" 
        //                   autoComplete="off"  
        //                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-yellow-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-black focus:z-10 sm:text-sm" 
        //                   placeholder="Codigo de usuario o email"
        //                   onChange={ handleInputChange }
        //           />
                
        //         </div>

        //         <div>

        //           <input id="password" 
        //                 name="password" 
        //                 type="password" 
        //                 autoComplete="off" 
        //                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-yellow-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-black focus:z-10 sm:text-sm" 
        //                 placeholder="Contraseña"
        //                 onChange={ handleInputChange }
        //           />
                        
        //         </div>
            
         
        //         <div className="form-check m-3">
        //         <input className="form-check-input" type="radio" name="tipoUsuario" value="01" onChange={ handleInputChange } defaultChecked />
        //           <label className="form-check-label">
        //             Cremigal
        //           </label>
        //         </div>

        //         <div className="form-check m-3">
        //         <input className="form-check-input" type="radio" name="tipoUsuario" value="02" onChange={ handleInputChange }/>
        //             <label className="form-check-label">
        //                  Capacitación
        //             </label>
        //         </div>                              

        //         <div>
                  //  <button type="submit" 
                  //         className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  // >
                  //   Ingresar
                  // </button>

                  // {

                  //   ( error ) && <p>Error no se encontro el usuario</p>

                  // }

      //           </div>
      //         </div>
      //       </form>         
      //   </div>
      //  </div>

     
    )
}
