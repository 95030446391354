import React, {useState} from 'react';
import { Error } from '../ui/Error';

import Swal from 'sweetalert2';

export const FormEfectivo = ( {cobrado, setCobrado, clienteSelect} ) => {

    const [error, setError] = useState(false);

    const [inputEfectivo, setInputEfectivo] = useState({
        idmCobro: 1,
        medioCobro: 'Efectivo',
        idCliente: clienteSelect, 
        valor: '',
        obs: ''
    });

    const { valor, obs } = inputEfectivo;

    const handleChange = (e) => {
        
        setInputEfectivo({
            ...inputEfectivo,
            [ e.target.name ] : e.target.value
        })
        //console.log(efectivo);
    }

    // Al enviar el formulario
    const handleSubmit = (e) => {

        e.preventDefault();

        //Valido que se ingresen numeros
        if ( parseInt(valor) > 0) {

            //Lo acumulo en el array donde guardo las cobranzas
            setCobrado([
                ...cobrado ,
                inputEfectivo
            ])

            setInputEfectivo({
                idmCobro: 1,
                medioCobro: 'Efectivo',
                idCliente: clienteSelect, 
                valor: '',
                obs: ''
            });   

            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Cobro Agregado',
                showConfirmButton: false,
                timer: 1500
              })

        } else {
            setError(true);

            setTimeout(() => {
                setError(false)
            }, 2000);
        } 

    }
    return (

        <div className="container">
            <div className="row">
                <div className="col order-first flex formulario-efectivo">

                    { error && <Error mensaje="Por favor ingrese un valor mayor a cero." /> }

                    <form onSubmit={ handleSubmit }> 
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label">Efectivo:</label>

                                <input 
                                    type="number" 
                                    // pattern="[1-9]*"
                                    maxLength="12"
                                    name="valor"
                                    className="form-control"
                                    placeholder="Importe..."  
                                    autoComplete="off"                               
                                    onChange={ handleChange }
                                    value={ valor }
                                    required
                                />
                            </div>
                            <div className="col-9">
                                <label className="form-label">Observaciones:</label>

                                <input 
                                    type="text" 
                                    // pattern="[1-9]*"
                                    name="obs"
                                    className="form-control"
                                    placeholder="Obsevaciones..."  
                                    autoComplete="off"                               
                                    onChange={ handleChange }
                                    value={ obs }
                                />
                            </div>                            
                        </div>    

                        <button type="submit" className="btn btn-success mt-3 btn-add-cobranza"> 
                                    {`AGREGAR  `} 
                                    <i className="bi bi-box-arrow-down"></i>
                        </button>  
                    </form>
                </div>
            </div>
        </div>
    )
}
