import React, { useEffect, useState, useMemo } from 'react';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';

export const TablaModoLectura = ( {facturas, cobros} ) => {


    const [total, setTotal] = useState(0);
    const [totalCobros, setTotalCobros] = useState(0);

    const columnsFactura = useMemo( () =>  [{ title: 'Id', field: 'idfactura', cellStyle: { textAlign: 'center'} },
                                { title: 'Fecha', field: 'fecha', cellStyle: { textAlign: 'center'} },
                                { title: 'Tipo', field: 'tipo', cellStyle: { textAlign: 'center'} },
                                { title: 'Serie', field: 'serie', cellStyle: { textAlign: 'center'} },
                                { title: 'Numero', field: 'nrodoc', cellStyle: { textAlign: 'center'} },
                                { title: 'Valor ($)', field: 'valor', cellStyle: { textAlign: 'right'} },
                                { title: 'Sdo. a Canc. ($)', field: 'saldo', cellStyle: { textAlign: 'right'} },
                                { title: 'Sdo. a Liquidar ($)', field: 'monto', cellStyle: { textAlign: 'right'} }] ,[] ) ;

    const columnsCobranza = useMemo( () => [ { title: 'Medio de Cobro', field: 'des_mcobro', cellStyle: { textAlign: 'center'} },
                                { title: 'Id. Recibo', field: 'idrecibo', cellStyle: { textAlign: 'center'}},
                                { title: 'Banco', field: 'idbanco', cellStyle: { textAlign: 'center'}},
                                { title: 'Fecha', field: 'emision', cellStyle: { textAlign: 'center'}},
                                { title: 'Vencto.', field: 'vcto', cellStyle: { textAlign: 'center'}},
                                { title: 'Numero', field: 'numero', cellStyle: { textAlign: 'center'}},
                                { title: 'Provincia', field: 'prov', cellStyle: { textAlign: 'center'}},
                                { title: 'Banco Ch', field: 'bcoch', cellStyle: { textAlign: 'center'}},
                                { title: 'Sucursal Ch', field: 'ctach', cellStyle: { textAlign: 'center'}},
                                { title: 'Cuenta Ch', field: 'agech', cellStyle: { textAlign: 'center'}},
                                { title: 'CUIT Ch', field: 'cuitch', cellStyle: { textAlign: 'center'}},
                                { title: 'Valor ($)', field: 'valor', cellStyle: { textAlign: 'right'}} ], [] );  
                               
    useEffect(() => {

        setTotal(0); 
        

        if (facturas !== null && facturas !== undefined) {

            facturas.map( valor => {

                        setTotal( prevSate => prevSate + parseFloat(valor.monto) )                                  
        
                return total;
            })
        }

        setTotalCobros(0);

        setTimeout(() => {
            if (cobros !== null && cobros !== undefined) {

                console.log('Ingreso a acumular los cobros...');
    
                cobros.map( cobro => {
                    
                    console.log( parseFloat(cobro.valor) )
                    setTotalCobros( prevState => prevState + parseFloat(cobro.valor) ) 
                    
                    return totalCobros;
                } )

                console.log('Total Cobros: :', totalCobros );
            }
        }, 50);

        



    }, [cobros])


    return (
        <>
            
        { ( facturas !== null ) &&
              <>
                <span className="badge rounded-pill text-white mb-1 mt-3 totales" style={{backgroundColor:'#FF5733'}}>
                            Total a cancelar: <span> ${ total.toFixed(2) }</span>
                </span>
                    
                <hr />
            
                <MaterialTable
                    
                    columns={columnsFactura}
                    data={facturas}
                    title="FACTURAS"
                    options={{    
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            position: 'sticky',
                            padding: '7px', 
                            zIndex:"unset",
                            textAlign: 'center',                                                                                                            
                        },
                        pageSizeOptions: [5,10,20]
                    }}

                    localization={{
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No se encontraron resultados...'
                        },
                        toolbar: {
                            searchPlaceholder: 'Busqueda...'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas'
                        }
                    }}                        
                />
            </>
        }

        {/* Tabla de Cobranzas */}

        <hr />

        <span className="badge rounded-pill text-white mb-1 totales mb-3" style={{backgroundColor:'#FF5733'}}>
                         Total a cobrar: <span> ${ totalCobros.toFixed(2) } </span>
        </span>

        <MaterialTable
                    
                    columns={columnsCobranza}
                    data={cobros}
                    title="COBRANZA"
                    options={{    
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            position: 'sticky',
                            padding: '7px', 
                            zIndex:"unset",
                            textAlign: 'center',                                                                                                        
                        },
                        pageSizeOptions: [5,10,20]
                    }}

                    localization={{
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No se encontraron resultados...'
                        },
                        toolbar: {
                            searchPlaceholder: 'Busqueda...'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas'
                        }
                    }}                        
                />   

                
            <div className="row d-flex text-center mt-5 mb-5">
                <form>

                    <Link                         
                        to="/recibo"   
                        className="m-3"                     
                    >
                        <button className="btn btn-dark animate__animated animate__rotateIn" type="button"
                        > 
                            <i className="bi bi-clipboard-x"></i>   
                            {`  VOLVER A RECIBOS`} 
                        </button> 
                    </Link> 

                </form>
            </div>           


        </>
    )
}
