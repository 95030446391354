import axios from 'axios';
import config from '../config.json';

export const getRecibo = async ( reciboid ) => {

    let aux = {};

    const url = `${config.SERVER_URL}/gets/getRecibo.php?idrecibo=${reciboid}`;

    console.log(url);

    await axios.get(url).then( res => {
        aux = res.data;
   } );

   console.log('salida desde la funcion: ', aux)

   return aux;

}
