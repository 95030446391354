import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import { Spinner } from '../ui/Spinner';
import { TablaFacturas } from '../tables/TablaFacturas';
import { TablaResumen } from '../tables/TablaResumen';

import { FormCheque } from '../forms/FormCheque';
import { FormEfectivo } from '../forms/FormEfectivo';
import { FormTransferencia } from '../forms/FormTransferencia';
import { FormRetencion } from '../forms/FormRetencion';


import { getProvincias } from '../../selectors/getProvincias';
import { getFacturas } from '../../selectors/getFacturas';
import { getBancos } from '../../selectors/getBancos';

import { obtenerToken } from '../../helpers';

import Swal from 'sweetalert2';
import config from '../../config.json';

export const AltaReciboScreen = ({history}) => {
    
    const [clienteSelect, setClienteSelect] = useState('');
    const [cobrado, setCobrado] = useState([]);
    const [totalImporteCobrado, setTotalImporteCobrado] = useState(0)
    const [bancos, setBancos] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [facturasDB, setFacturasDB] = useState([]);
    const [facturasEdit, setFacturasEdit] = useState(0);
    // const [consultar, setConsultar] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [redirigir, setRedirigir] = useState(true);

    const authContext = useContext(AuthContext);
    const { user: { tipoUsuario, idvendedor },  clientesDB } = authContext;
        
    const consultarBancos = async () => {
            //console.log('Consultar bancos');
        const result = await getBancos();
        // console.log(result);
        setBancos(result);
    }

    const consultarProvincias = async () => {
        //console.log('Consultar provincias');
        const result = await getProvincias();
        //console.log(result);
        setProvincias(result);
    }
    
    //console.log(provincias);
    //const memoBancos = 
    React.useMemo( () => { consultarBancos() }, [] ) ;
    //const memoProvincias = 
    React.useMemo( () => { consultarProvincias() }, [] ) ; 
        
    useEffect(() => {

            // Para acumular el cobrado
            setTotalImporteCobrado(0)

            //Obtengo el total cobrado
            cobrado.map( c => ( 
                    setTotalImporteCobrado( prevState => prevState + parseFloat(c.valor))
            ))

            // Para acumular lo editado en las facturas
            setFacturasEdit(0);

            //Obtengo el total de facturas
            facturasDB.map( (valor) => {
                    
                if( typeof valor.sliquidar === 'number' ) {
            
                    if (valor.sliquidar !== 0){                  
                        console.log(valor.sliquidar);
                        setFacturasEdit( prevSate => prevSate + parseFloat(valor.sliquidar) )   
                    }
                }

            } )        
                
    }, [cobrado, facturasDB])

    //Submit del alta recibo
    const handleSubmit = async (event) => {

        event.preventDefault();

        console.log('Importe total cobrado:', totalImporteCobrado);
        console.log('Importe total facturas editadas:', facturasEdit);                    
        console.log('Procesando el alta recibo....');

        //console.log('Cantidad Array Cobrado:', cobrado.length);
        //console.log( 'Token (getTime): ', new Date().getTime() );

        console.log('Obtener Token...');

        const token = obtenerToken();
        console.log('Token: ', token );

        const grabarFacturas = async () => {

                //Primer paso enviar las facturas
                facturasDB.map( async ( factura ) => {

                if( typeof factura.sliquidar === 'number' ){

                    if (factura.sliquidar !== 0) {

                        console.log('Procesando facturas...');
                        
                        //console.log(JSON.stringify(factura));                        
                        const url=`${config.SERVER_URL}/sets/setFactura.php?token=${token}&json={"idfactura":${factura.idfactura},"monto":${parseFloat(factura.sliquidar)}}`;
                        
                        console.log(url);

                        await axios.get(url)
                                    .then( resp => console.log(resp) )
                                    .catch(err => {
                                                
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: 'ERROR',
                                                        text: err,
                                                        footer: 'Por favor contacte al administrador'
                                                    })
                                                                                                            
                                                    console.log(err)

                                                    setRedirigir(false);
                                                });


                    }
                }                                          
            })
        }

        const grabarMedioCorbro = async () => {

            //Segundo paso envio la cobranza. Tengo que armar un nuevo objeto
            //Genero las URL de acuerdo al medio de cobro
            cobrado.map( async (cobro) => {

                let url = '';

                switch (cobro.medioCobro) {
                    case 'Efectivo':
                        url = `${config.SERVER_URL}/sets/setMCobro.php?token=${token}&json={"idmcobro":${cobro.idmCobro},"idbanco":0,"valor":${cobro.valor},"numero":"","emision":"","vcto":"","datoch":"","bcoch":"","agech":"","ctach":"","cuitch":"","prov":"","observ":"${cobro.obs}"}`;

                        break;

                    case 'Transferencia':
                        url = `${config.SERVER_URL}/sets/setMCobro.php?token=${token}&json={"idmcobro":${cobro.idmCobro},"idbanco":${cobro.idbanco},"valor":${cobro.valor},"numero":"${cobro.numero}","emision":${(cobro.fecha.toString()).replace('-','').replace('-','')},"vcto":"","datoch":"","bcoch":"","agech":"","ctach":"","cuitch":"","prov":"","observ":""}`;
                        break;

                    case 'Cheque':                    
                        url = `${config.SERVER_URL}/sets/setMCobro.php?token=${token}&json={"idmcobro":${cobro.idmCobro},"idbanco":0,"valor":${cobro.valor},"numero":"${cobro.numero}","emision":${(cobro.fecha.toString()).replace('-','').replace('-','')},"vcto":${(cobro.vencimiento.toString()).replace('-','').replace('-','')},"datoch":"","bcoch":"${cobro.bancoCh}","agech":"${cobro.sucursalCh}","ctach":"${cobro.cuentaCh}","cuitch":${cobro.cuitCh},"prov":"","observ":"${cobro.echeqs?'echeqs': ''}"}`;
                    break
                                        
                    default:
                        //entro por retencion
                        url = `${config.SERVER_URL}/sets/setMCobro.php?token=${token}&json={"idmcobro":${cobro.idmCobro},"idbanco":0,"valor":${cobro.valor},"numero":"${cobro.numero}","emision":${(cobro.fecha.toString()).replace('-','').replace('-','')},"vcto":"","datoch":"","bcoch":"","agech":"","ctach":"","cuitch":"","prov":"${cobro.provincia}","observ":""}`;
                        
                        break
                }

                console.log(url);

                 await axios.get(url)
                            .then( resp => console.log(resp) )
                            .catch (err => {
                                
                                 //Muestro el error
                                Swal.fire({
                                    icon: 'error',
                                    title: 'ERROR',
                                    text: err,
                                    footer: 'Por favor contacte al administrador'
                                })
                                                                                        
                                console.log(err)

                                setRedirigir(false);
                            });
                    

            })
        }

        const grabarImporteFinal = async () => {

            console.log('Total Importe Cobrado: ', totalImporteCobrado);

            const url = `${config.SERVER_URL}/sets/setRecibo.php?token=${token}&vendedor=${idvendedor}&cliente=${clienteSelect}&empresa=${tipoUsuario}&monto=${totalImporteCobrado}`;
        
            console.log(url);
    
            await axios.get(url)
                       .then(resp => { console.log(resp)

                            //Muestro el error
                            if( !resp.data.estado ) {

                                Swal.fire({
                                    icon: 'error',
                                    title: 'ERROR al registar Recibo.',
                                    text: resp.data.resultado,
                                    footer: 'Por favor contacte al administrador'
                                })                                
                            }                                                                                                
                       } )
                       .catch(err => {
                                    
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: err,
                            footer: 'Por favor contacte al administrador'
                        })
                                                                                
                        console.log(err)

                        setRedirigir(false);
                    });

        }

        const confirmarMensaje = () => {


            //Ventana para aceptar
             Swal.fire({
                title: 'Confirmar recibo',
                text: "Esta seguro que desea guardar la informacion ingresada?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'CONFIRMAR',
                cancelButtonText: 'CANCELAR'
            
            }).then((result) => {

                if (result.isConfirmed) {

                    grabarFacturas();

                    setTimeout(() => {                       
                            grabarMedioCorbro();                      
                    }, 400);
                    
                    

                    setTimeout(() => {                        
                            grabarImporteFinal();
                    }, 800);
                    

                    Swal.fire(
                        'Guardado',
                        'La informacion se guardo con exito',
                        'success'
                    )
                    
                    // reinicio setCobrado
                    setCobrado([]);                                                        


                    
                    setTimeout(() => {
                        //Redireccion a la pagina de recibos                   
                        history.push('/recibos');          
                    }, 1600);
                      
                }
                
            })
        }
        
            //Si el acumulado en facturas es mayor que el cobrado muestro un mensaje de error
            if ( ( ( parseInt(totalImporteCobrado) < parseInt(facturasEdit) ) && facturasEdit > 0 )  )
            {
                //console.log('El importe cobrado es menor al saldo a cancelar de las facturas.');
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR: Verifique el total a cobrar',
                    text: 'El total a cobrar es menor al saldo a cancelar de las facturas.',
                    footer: '<strong>Cremigal Cobranzas</strong>'
                })  

            } else {
                
                //Lanzo el submit para registrar las facturas
                confirmarMensaje();

            }            
    }


    const handleSelect = ( e ) => {

        //Guardo el cliente seleccionado en el select
        setClienteSelect(e.value);

        const consultarFacturas = async (clienteid) => {

            //console.log('Consulte facturas y bancos');
            // console.log('Consultar facturas: idvendedor', idvendedor);
            const result = await getFacturas( clienteid, tipoUsuario);        
            setFacturasDB(result);
            // console.log('Consultar facturas:', facturasDB);    
        }

        //console.log( 'clienteid seleccionado:', clienteSelect );

        //Muestro un spinner de carga
        setShowSpinner(true);

        // Oculto la tabla y el resto de los componentes de la pantalla
        setShowComponent(false);
        
        // Si cambia el cliente reinicio el cobrado
        //console.log('Reinicio cobrado');
        setCobrado([]);

        //paso el cliente seleccionado
        setTimeout(() => {
            setShowSpinner(false);
            consultarFacturas(e.value);

            setShowComponent(true);
        }, 2000);                
    }

    return (
        
        <div className="container animate__animated animate__fadeIn">

                <div className="row col-md-4">                
                    <Select 
                        options={clientesDB}
                        onChange={ handleSelect }
                        placeholder="-- Seleccionar Cliente --"
                        className="mt-4"                    
                     />
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-12">
                            { showComponent && <TablaFacturas 
                                                    facturasDB={facturasDB} 
                                                    setFacturasDB={setFacturasDB} 
                                                /> }
                    </div>
                </div>

            <div className="row m-2 justify-content-center">
                <div className="col-md-8  animate__animated animate__fadeInLeft">

                    { showComponent &&
                        
                                <div className="col d-flex">
                                    <button className="btn btn-dark m-3 btn-cobranzas" type="button" data-bs-toggle="collapse" data-bs-target="#cargaEfectivo" aria-expanded="false" aria-controls="cargaEfectivo">
                                        <i className="bi bi-arrow-bar-right"></i>
                                        {`  EFECTIVO`}
                                    </button>

                                    <button className="btn btn-dark m-3 btn-cobranzas" type="button" data-bs-toggle="collapse" data-bs-target="#cargaCheque" aria-expanded="false" aria-controls="cargaCheque">
                                        <i className="bi bi-arrow-bar-right"></i>
                                        {`  CHEQUE`}
                                    </button>

                                    <button className="btn btn-dark m-3 btn-cobranzas" type="button" data-bs-toggle="collapse" data-bs-target="#cargaRetencion" aria-expanded="false" aria-controls="cargaRetencion">
                                         <i className="bi bi-arrow-bar-right"></i>
                                        {`  RETENCION`}
                                    </button>

                                    <button className="btn btn-dark m-3 btn-cobranzas" type="button" data-bs-toggle="collapse" data-bs-target="#cargaTransferencia" aria-expanded="false" aria-controls="cargaTransferencia">
                                        <i className="bi bi-arrow-bar-right"></i>
                                        {`  TRANSFERENCIA`}
                                    </button>                                      
                                </div>                                                                                            
                    }
                </div>                      
            </div>        

            { showSpinner && <Spinner /> }

            <div className="collapse" id="cargaEfectivo">
                <div className="card card-body animate__animated animate__zoomInDown">
                    { showComponent && <FormEfectivo 
                                            cobrado={cobrado} 
                                            setCobrado={setCobrado} 
                                            clienteSelect={clienteSelect}
                                        />  }
                </div>
            </div>  

            <div className="collapse" id="cargaTransferencia">
                <div className="card card-body animate__animated animate__zoomInDown">
                    { showComponent && <FormTransferencia 
                                                bancos={bancos} 
                                                cobrado={cobrado} 
                                                setCobrado={setCobrado} 
                                                clienteSelect={clienteSelect}
                                        /> }
                 </div>
            </div>

            <div className="collapse" id="cargaRetencion">
                <div className="card card-body animate__animated animate__zoomInDown">
                     { showComponent &&  <FormRetencion 
                                                provincias={provincias} 
                                                cobrado={cobrado} 
                                                setCobrado={setCobrado}
                                                clienteSelect={clienteSelect}
                                        /> }
                </div>
            </div>     

            <div className="collapse" id="cargaCheque">
                <div className="card card-body animate__animated animate__zoomInDown">
                     { showComponent &&  <FormCheque 
                                            cobrado={cobrado} 
                                            setCobrado={setCobrado} 
                                            clienteSelect={clienteSelect}
                                        /> }
                </div>
            </div>                 
            
            

            { showComponent && 
                    <div> 
                        <TablaResumen cobrado={cobrado} 
                                      setCobrado={setCobrado} 
                                      totalImporteCobrado={totalImporteCobrado}
                        />  
                    </div> 
            }

                    <div className="row d-flex text-center ">
                        <form onSubmit={ handleSubmit }>
                            <button
                                className="btn btn-outline-dark mr-5 animate__animated animate__rotateIn"
                                type="submit"
                                disabled={cobrado.length < 1 }
                            >
                                <i className="bi bi-check-all"></i>
                                {`  GUARDAR`}
                            </button>

                            <Link                         
                                to="/recibo"   
                                className="m-3"                     
                            >
                                <button className="btn btn-dark animate__animated animate__rotateIn" type="button"
                                > 
                                    <i className="bi bi-clipboard-x"></i>   
                                    {`  CANCELAR`} 
                                </button> 
                            </Link> 
                        </form>
                    </div>                                
                            
                
            <div className="container mt-5">
                
            </div>

            { console.log('Total cobrado:', cobrado) }
            { console.log('facturasEdit:',  facturasEdit) }
                    
       </div>
    
    )
} 

