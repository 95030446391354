import React, { useEffect, useReducer, useState } from 'react';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/authReducer';
import { AppRouter } from './routes/AppRouter';
import { getClientes } from './selectors/getClientes';

import './styles/styles.css';

const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged: false }
}

let opcionesClientes = [];

export const CremigalApp = () => {

  const [user, dispatch] = useReducer(authReducer, {}, init);
  const [clientesDB, setclientesDB] = useState([]);

    //UseEffect para el usuario
    useEffect(() => {
      localStorage.setItem('user', JSON.stringify(user))
    }, [user])  

    //UseEffect para el usuario
    useEffect(() => {

      if(user.logged) {
        
        const consultarClientes = async () => {

          //console.log('Me ejecute consultarClientes');
      
          const result =  await getClientes(user.idvendedor)  //( await getClientes(user.idvendedor) );
      
          opcionesClientes = result.map( (elemento) => {
              return { value: `${elemento.idcliente}`, label: `(${elemento.codigo}) - ${elemento.razonsoc}`};
          });
      
          setclientesDB([...opcionesClientes]);
      
          // console.log('opcionesClientes:', opcionesClientes);
          // console.log('clientesDB:', clientesDB);
        }      
        consultarClientes()
      }
    }, [user])  

  return (

      <AuthContext.Provider value={{ user, dispatch, clientesDB }}>
          <AppRouter />
      </AuthContext.Provider>

  )
}

