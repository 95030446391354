import axios from 'axios';
import config from '../config.json';

export const getUsuario = async ( usuario, password ) => {

    // console.log('Ingreso en funcion getUsuario');
    let aux = {};

    const url = `${config.SERVER_URL}/gets/getUsuario.php?codigo=${usuario}&pass=${password}`;

    // const respuesta = await clienteAxios.get(url);

    //Obtengo los datos del servicio
     await axios.get(url).then( res => {
         aux = res.data;
    } );

    // console.log(aux);

    return aux;
}