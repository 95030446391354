import React, {useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { getRecibos } from '../../selectors/getRecibos';
import { TablaRecibos } from '../tables/TablaRecibos';

export const ReciboScreen =  () => {

    const [mount, setMount] = useState(true);

    const [recibosDB, setRecibosDB] = useState([]);

    const authContext = useContext(AuthContext);
    const { user: { idvendedor, tipoUsuario } } = authContext;

    useEffect( () => {

        if (mount){
            
            const consultarRecibos = async () => {

                console.log('Consulta recibos');
        
                const result =  await getRecibos(idvendedor, tipoUsuario);
                setRecibosDB(result)
                //console.log(result);
            }

            setTimeout(() => {
                consultarRecibos()  
            }, 800);
                
        }
            return () => {
                setMount(false);
        }

    }, [mount])


    return (
        <div className="container">

            <div className="row">
                <div className="col-md-12 mx-auto">
                    <div className="d-flex flex-row-reverse bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                            <Link                         
                                    to="/altarecibo"   
                                                       
                                >

                                    <button className="btn btn-outline-dark animate__animated animate__fadeIn" type="button"> 
                                    
                                    <i className="bi bi-cash-stack"></i>                                                                                                    
                                        { `  ALTA RECIBO` }
                                    </button> 
                                </Link>  
                        </div>
                    </div>

                    <TablaRecibos recibosDB={recibosDB} setRecibosDB={setRecibosDB} />

                </div>
            </div>
        </div>
    )
}

