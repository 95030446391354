import React, { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';

export const TablaFacturas =  ( {facturasDB, setFacturasDB} ) => {

    //console.log('Recargo componente TablaFacturas:', facturasDB);
    
    //Acumulador
    const [total, setTotal] = useState(0);

    //Columnas de la tabla
    // const [columns, setColumns] =  useState([
    //     { title: 'Id', field: 'idfactura' },
    //     { title: 'Fecha', field: 'fecha'},
    //     { title: 'Tipo', field: 'tipo'},
    //     { title: 'Serie', field: 'serie'},
    //     { title: 'Numero', field: 'nrodoc'},
    //     { title: 'Valor ($)', field: 'valor'},
    //     { title: 'Sdo. a Canc. ($)', field: 'valor'},
    //     { title: 'Sdo. a Liquidar ($)', field: 'sliquidar'}
    // ]);

    //const [columns, setColumns] =  useState();

     const columns =   [{ title: 'Id', field: 'idfactura', cellStyle: { textAlign: 'center'} }  ,
                        { title: 'Fecha', field: 'fecha', cellStyle: { textAlign: 'center'} },
                        { title: 'Tipo', field: 'tipo', cellStyle: { textAlign: 'center'} },
                        { title: 'Serie', field: 'serie', cellStyle: { textAlign: 'center'} },
                        { title: 'Numero', field: 'nrodoc', cellStyle: { textAlign: 'center'} },
                        { title: 'Valor ($)', field: 'valor', cellStyle: { textAlign: 'right'} },
                        { title: 'Sdo. a Canc. ($)', field: 'saldo', cellStyle: { textAlign: 'right'} },
                        { title: 'Sdo. a Liquidar ($)', field: 'sliquidar', cellStyle: { textAlign: 'right'} }] ;
      
    useEffect(
        () => {
                setTotal(0);   
                            
                facturasDB.map( (valor) => {
                    //     console.log('Cambiaron las facturas, ejecuto useMemo');
                    if( typeof valor.sliquidar === 'number' ) {
            
                        //if (valor.sliquidar > 0){
            
                            console.log(valor.sliquidar);

                            // total = total + valor.sliquidar
                            setTotal( prevSate => prevSate + valor.sliquidar )                                  
            
                            return total;
                       // }
                    } 
                })
            }

        , [facturasDB],
      );


    return (
        <>
            {/* <div className="align-items-center" style={{ maxWidth: '80%', fontFamily: 'sans-serif', alignItems: 'center'}}> */}

                
                <span className="badge rounded-pill text-white mb-1 mt-3 totales" style={{backgroundColor:'#FF5733'}}>
                         Total a cancelar: <span> ${ total.toFixed(2) }</span>
                </span>
                
                <hr />

                <MaterialTable

                    columns={columns}
                    data={facturasDB}
                    title="FACTURAS"
                    options={{    
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            position: 'sticky',
                            padding: '7px', 
                            zIndex:"unset",
                            textAlign: 'center',                                                                                                            
                        },
                        pageSizeOptions: [5,10,20], 
                        actionsColumnIndex: -1,                        
                     }}

                     //isLoading="true"

                     actions={[
                        {
                            header: {
                                actions: '' //  actions: 'Any text'
                            },
                          icon: 'edit',
                          tooltip: 'Editar saldo',
                          onClick: (event, rowData) => {
                            // Do save operation

                                    //Modal para ingresar el importe
                                    { const { value: saldo } =  Swal.fire({
                                            title: 'Ingrese el saldo a cancelar de la factura:',
                                            icon: 'question',
                                            input: 'number',
                                            inputLabel: 'SALDO:',
                                            confirmButtonText: 'GUARDAR',
                                            cancelButtonText: 'CANCELAR',
                                            showCancelButton: true,
                                            
                                            inputValidator: (value) => {
  
                                            if (!value) {
                                                return 'Por favor ingrese un valor';
                                            }
                                            
                                            //validacion si el saldo es negativo
                                             if ( rowData.saldo < 0) {

                                                if ( parseFloat(value) < rowData.saldo || parseFloat(value) > 0 ) {
                                                    return 'El saldo ingresado es mayor al saldo a cancelar o incorrecto';
                                                }
                                            } else {

                                                if ( (parseFloat(value) > rowData.saldo) || (parseFloat(value) < 0)  ) { 
                                                    return 'El saldo ingresado es mayor al saldo a cancelar incorrecto' }
                                            
                                            }                                                                                                                        
                                            
                                            //Convierto a float el valor ingresado por el usuario
                                            rowData.sliquidar = parseFloat(value);  
                                            
                                            //Actualizo el state donde se guardan los datos con el valor ingresado
                                            const dataUpdate = [...facturasDB];   
                                            //console.log(dataUpdate);
                                            const index = rowData.tableData.id;
                                            //console.log(index);
                                            dataUpdate[index] = rowData;
                                            //console.log(dataUpdate[index]);
                                            setFacturasDB([...dataUpdate]);
                                            // console.log(facturasDB);
                                        }
                                    })                        
                                }
                          }
                        }
                      ]}

                      localization={{
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No se encontraron resultados...'
                        },
                        toolbar: {
                            searchPlaceholder: 'Busqueda...'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas'
                        }
                    }}

                />
            {/* //</div>        */}
        </>
    )   
}     