import React, {useState,useRef, useEffect} from 'react'
import { Error } from '../ui/Error';

import { fechaLimite, verifyCuit } from '../../helpers';

import Swal from 'sweetalert2';

export const FormCheque = ( {cobrado, setCobrado, clienteSelect} ) => {

    const [autoCompletar, setAutoCompletar] = useState(false);

    const refCodigo = useRef();
    const refInputBanco = useRef(); 
    const refInputAgencia = useRef();
    const refInputSucursal = useRef();
    const refInputNumero = useRef();

    const [error, setError] = useState(false);

    const [cheque, setCheque] = useState({
        idmCobro: 3,
        medioCobro: 'Cheque',
        idcliente: clienteSelect,
        codigo: '',
        numero: '',
        fecha: '',
        vencimiento: '',
        bancoCh: '',
        sucursalCh: '',
        cuentaCh: '',
        cuitCh: '',
        valor: '',
        echeqs: false
    })

    const { medioCobro, codigo, numero, fecha, vencimiento, bancoCh, sucursalCh, cuentaCh, cuitCh, valor, echeqs } = cheque;

    useEffect( () => {

        //console.log(refCodigo.current.value);
        //if ( refCodigo.current.value.length === 31 ) {

            //Cuando tengo los 31 caracteres ejecuto el fill de los campos

            if ( autoCompletar ) {

                refInputBanco.current.value = refCodigo.current.value.substr(1,3);
                refInputAgencia.current.value = refCodigo.current.value.substr(4,3);            
                refInputSucursal.current.value = refCodigo.current.value.substr(7,4);
                refInputNumero.current.value = refCodigo.current.value.substr(11,8);

                //console.log(refInputAgencia.current.value)

                const chequeAux = {
                    idmCobro: 3,
                    medioCobro: 'Cheque',
                    idcliente: clienteSelect,
                    codigo: refCodigo.current.value,
                    numero: refInputNumero.current.value,
                    fecha: '',
                    vencimiento: '',
                    bancoCh: refInputBanco.current.value,
                    sucursalCh: refInputSucursal.current.value,
                    cuentaCh: refInputAgencia.current.value,
                    cuitCh: '',
                    valor: '',
                    echeqs: false
                }

                setCheque(chequeAux);    

                setAutoCompletar(false);
            }
        
            // return () => {
            //     setAutoCompletar(false);
            // }
                
    }, [cheque])

    const handleChange = ( e ) => {
        
        setCheque({
            ...cheque,
            [ e.target.name ]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        });
                
        if ( e.target.name === 'codigo' ) {

            setAutoCompletar(true);

        }

    }

    const handleSubmit = (e) => {
        
        e.preventDefault();        
        
        if ( parseInt(numero) > 0 && 
            parseInt(bancoCh) > 0 && 
            parseInt(cuitCh) > 0 && 
            parseInt(valor) > 0 && 
            verifyCuit(cuitCh) &&
            fecha <= vencimiento &&
            vencimiento >= fechaLimite() ) {

            setCobrado([
                ...cobrado,
                cheque
            ])
            
            

            //vacio el formulario
            setCheque({
                idmCobro: 3,
                medioCobro: 'Cheque',
                idcliente: clienteSelect,
                codigo: '',
                numero: '',
                fecha: '',
                vencimiento: '',
                bancoCh: '',
                sucursalCh: '',
                cuentaCh: '',
                cuitCh: '',
                valor: '',
                echeqs: false
            });

            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Cobro Agregado',
                showConfirmButton: false,
                timer: 1500
              })

            //setAutoCompletar(true);

        } else {

            setError(true);

            setTimeout(() => {
                setError(false)
            }, 5000);
        }    
    }

    return (
            <div className="container">
                <div className="row">    

                    { error && <Error mensaje="- Valide que los numeros ingresados sean mayores a cero.
                                            *- Verifique que la CUIT del cheque sea válido.
                                            *- La fecha del cheque no puede ser mayor a la fecha de vencimiento.
                                            *- La fecha de vencimiento no puede ser menor a 1 año." /> }

                    <form onSubmit={ handleSubmit }>                    
                    
                    <label className="form-label mt-3">Cheque:</label>
                        <div className="row">    

                            <div className="col-12">                            
                                <label className="form-label"> Codigo:</label>
                                <input 
                                    type="text" 
                                    name="codigo"
                                    value={ codigo }
                                    className="form-control"                            
                                    onChange={ handleChange }  
                                    ref={refCodigo}    
                                    autoComplete="off"                                                              
                                />
                            </div>

                            <div className="col-3">                            
                                <label className="form-label"> Banco:</label>
                                <input 
                                    id="bancoCh"
                                    type="text" 
                                    name="bancoCh"
                                    maxLength="3"
                                    pattern="[0-9]*"
                                    value={ bancoCh }
                                    className="form-control"                            
                                    onChange={ handleChange }
                                    autoComplete="off"                                       
                                    required
                                    ref={refInputBanco}
                                />
                            </div>

                            <div className="col-3">                            
                                <label className="form-label"> Agencia:</label>
                                <input 
                                    type="text" 
                                    name="cuentaCh"
                                    value={ cuentaCh }
                                    className="form-control"                            
                                    onChange={ handleChange }   
                                    autoComplete="off" 
                                    required                                
                                    ref={refInputAgencia}                        
                                />
                            </div>

                            <div className="col-3">                            
                                <label className="form-label"> Sucursal:</label>
                                <input 
                                    type="text" 
                                    name="sucursalCh"
                                    value={ sucursalCh }
                                    className="form-control"                            
                                    onChange={ handleChange } 
                                    autoComplete="off" 
                                    required                                                 
                                    ref={refInputSucursal}            
                                />
                            </div>

                            <div className="col-3">                            
                                <label className="form-label"> Numero:</label>
                                <input 
                                    type="number" 
                                    name="numero"
                                    value={ numero }
                                    className="form-control"                            
                                    onChange={ handleChange }                                       
                                    required                                    
                                    ref={refInputNumero}                           
                                />
                            </div>


                            <div className="col-3">                            
                                <label className="form-label"> Fecha:</label>
                                <input 
                                    type="date" 
                                    name="fecha"
                                    value={ fecha }
                                    className="form-control"                            
                                    onChange={ handleChange }    
                                    required                            
                                />
                            </div>

                            <div className="col-3">                            
                                <label className="form-label"> Vcto. :</label>
                                <input 
                                    type="date" 
                                    name="vencimiento"
                                    value={ vencimiento }
                                    className="form-control"                            
                                    onChange={ handleChange }    
                                    required                            
                                />
                            </div>

                            <div className="col-3">                            
                                <label className="form-label"> Cuit:</label>
                                <input 
                                    type="text" 
                                    name="cuitCh"
                                    maxLength="11"
                                    value={ cuitCh }
                                    className="form-control"                            
                                    onChange={ handleChange } 
                                    autoComplete="off" 
                                    required                               
                                />
                            </div>

                            <div className="col-3">                            
                                <label className="form-label"> Importe $:</label>
                                
                                <input 
                                    type="number" 
                                    name="valor"
                                    maxLength="12"
                                    className="form-control"
                                    placeholder="Importe..."  
                                    autoComplete="off"                               
                                    onChange={ handleChange }
                                    value={ valor }
                                    required
                                />
                            </div>

                            <div className="col-3">                                                            
                                <input 
                                    id="chkEcheq"
                                    type="checkbox" 
                                    name="echeqs"
                                    checked={echeqs}
                                    placeholder="echeqs"  
                                    onChange={ handleChange }
                                />   
                                <label className="form-label">E-Cheq</label>
                            </div>
                        </div>        
                            <button type="submit" className="btn btn-success mt-3 btn-add-cobranza"> 
                                {`AGREGAR  `} 
                                <i className="bi bi-box-arrow-down"></i>
                            </button>                      
                    </form>                                
                </div>
            </div>

    )
}
