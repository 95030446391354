import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getRecibo } from '../../selectors/getRecibo';
import Select from 'react-select';
import { TablaModoLectura } from '../tables/TablaModoLectura';
 

export const ReciboViewScreen = ( {history} ) => {

    const [recibos, setRecibos] = useState([]);
    const [consultarRecibo, setConsultarRecibo] = useState(false);
    
    const { idrecibo } = useParams();
    console.log('Parametro recibo id: ', idrecibo);


    useEffect(() => {
        
        setConsultarRecibo(true);

        const obtenerRecibos = async () => {

            const result =  await getRecibo(idrecibo);
            setRecibos(result);
    
            console.log(result);
        }
    
        obtenerRecibos();

        return () => {

            setConsultarRecibo(false);
        }
    }, [])

    //Desestructuro Recibos
    const { cobros, facturas, idcliente, razonsoc } = recibos;

    console.log(cobros);
    console.log(facturas);
    console.log(idcliente);
    console.log(razonsoc);

    return (

        <div className="container animate__animated animate__fadeIn">

                <div className="row col-md-4">                
                    <Select 
                        //options={clientesDB}
                        //onChange={ handleSelect }
                        placeholder={`(${idcliente}) - ${razonsoc}`}
                        className="mt-4" 
                        isDisabled="true"                   
                     />
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-12 mt-5">

                         <TablaModoLectura
                                facturas={facturas} 
                                cobros={cobros}
                        /> 

                    </div>
                </div>
       
        </div>



    )
}
