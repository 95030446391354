
export const obtenerToken = () => {

        let token;

        // Armo el token con la fecha
        const date = new Date();      
        const year = date.getFullYear().toString();
        const month = (date.getMonth()+1).toString();
        const day =  (date.getDay()).toString();
        //console.log(mes);

        // console.log('Year: ', year);
        // console.log('Month: ', month.padStart(2, '0') );
        // console.log('Day: ', day.padStart(2, '0') );

        if (month < 10) {
                token = `${year}0${month}`;
        } else {
                token = `${year}${10}`;
        }

        if (day < 10) {
            token +=`0${day}`
        } else {
            token +=`${day}`
        }

        //Separador para horas minutos y segundos
        token += '_';
                
        const hour = date.getHours();

        if (hour < 10) {
            token += `0${hour.toString()}`
        } else {
            token += `${hour.toString()}`
        }

        //console.log(hour);
        const minutes = date.getMinutes();

        if (minutes < 10) {
            token += `0${minutes.toString()}`
        } else {
            token += `${minutes.toString()}`
        }

        const seconds = date.getSeconds();

        if (seconds < 10) {
            token += `0${seconds.toString()}`
        } else {
            token += `${seconds.toString()}`
        }

        return token;
}

export const verifyCuit = (cuit) => {

    if (cuit.length !== 11) {
        return false;
    }

    let acumulado = 0;
    let digitos = cuit.split('');
    let digito = parseInt(digitos.pop());

    for (let i = 0; i < digitos.length; i++) 
    {
        acumulado += digitos[9 - i] * (2 + (i % 6));
    }

    let verif = 11 - (acumulado % 11);  

    if (verif === 11) 
    {
        verif = 0;
    } 
    else if (verif === 10) 
    {
        verif = 9;
    }

    return digito === verif;

};

export const fechaLimite = () => {

    var d = new Date();
    d.setDate(d.getDate()-365);

    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;

    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');

}