import React, {useState} from 'react';
import { Error } from '../ui/Error';

import Swal from 'sweetalert2';

import { fechaLimite } from '../../helpers';

export const FormRetencion = ( {provincias, cobrado, setCobrado, clienteSelect} ) => {

    const [error, setError] = useState(false);

    const [retencion, setRetencion] = useState({
        idcliente: clienteSelect,
        numero: '',
        idmCobro: '',
        fecha: '',
        provincia: '',
        valor: ''
    })

    const { numero, idmCobro,fecha, provincia, valor} = retencion;

    const handleChange = ( e ) => {
        
        setRetencion({
            ...retencion,
            [ e.target.name ]: ((e.target.name === 'idmCobro' ) ? parseInt(e.target.value) : e.target.value)
        });
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
        
        //Valido que los numeros ingresados sean mayores a cero
        if ( parseInt(numero) > 0 && 
            parseInt(valor) > 0 && 
            (idmCobro != 5 || (idmCobro == 5 && provincia != "")) &&
            fecha >= fechaLimite() ) {

            const retencionAll = {
                idcliente: clienteSelect,
                idmCobro,
                numero,            
                fecha,
                provincia,
                valor
            }
    
            switch (idmCobro) {
                case 4:
                    retencionAll.medioCobro = 'Retencion IVA'
                    break;
                    case 5:
                        retencionAll.medioCobro = 'Retencion Ingresos Brutos'
                        break;
                        case 6:
                            retencionAll.medioCobro = 'Retencion Ganancias'
                            break;
                            case 7:
                                retencionAll.medioCobro = 'Retencion SUSS'
                                break;
            
                default:
                    break;
            }
    
            //console.log(retencionAll);
    
            setCobrado([
                ...cobrado,
                retencionAll
            ])
    
            setRetencion({
                idcliente: clienteSelect,
                numero: '',
                idmCobro: '',
                fecha: '',
                provincia: '',
                valor: ''
            });

            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Cobro Agregado',
                showConfirmButton: false,
                timer: 1500
              })

        } else {
            setError(true);

            setTimeout(() => {
                setError(false)
            }, 5000);
        }       
    }

    return (
    

       <div className="container">
            <div className="row">  

                { error && <Error mensaje="- El numero de retención o importe deben ser mayores a cero.
                                    *- La fecha no puede ser menor 1 año.
                                    *- Para retencion de IIBB se deberá informar la provincia."/> }

                <form onSubmit={ handleSubmit }>                    
                
                <label className="form-label mt-3">Retencion:</label>
                    <div className="row">    

                        <div className="col-2">                            
                            <label className="form-label"> Numero:</label>
                            <input 
                                type="number" 
                                name="numero"
                                value={ numero }
                                className="form-control"                            
                                onChange={ handleChange }    
                                required                            
                            />
                        </div>

                        <div className="col-2">                            
                            <label className="form-label"> Tipo Retencion:</label>
                            <select 
                                name="idmCobro"
                                className="form-select"                                
                                onChange={ handleChange }
                                value={ idmCobro }
                                required
                            >
                                 <option defaultValue> -- Seleccionar -- </option>
                                
                                 <option key={4} value={4}> Retención IVA </option>
                                 <option key={5} value={5}> Retención Ingresos Brutos </option>
                                 <option key={6} value={6}> Retención Ganancias </option>
                                 <option key={7} value={7}> Retención SUSS </option>

                            </select>
                        </div>

                        <div className="col-2">                            
                            <label className="form-label"> Fecha retencion:</label>
                            <input 
                                type="date" 
                                name="fecha"
                                value={ fecha }
                                className="form-control"                            
                                onChange={ handleChange }   
                                required                             
                            />
                        </div>

                        <div className="col-3">                            
                            <label className="form-label"> Provincia:</label>
                            
                            <select 
                                name="provincia"
                                className="form-select"                                
                                onChange={ handleChange }
                                value={ provincia }
                                required
                                
                            >
                                <option defaultValue> -- Seleccionar -- </option>

                                {
                                    provincias.map( (provincia) => (
                                         <option 
                                            key={provincia.codigo} 
                                            value={provincia.codigo}
                                         >{provincia.codigo} - {provincia.descripcion}
                                         </option>
                                    ))
                                }
                            </select>
                        </div> 

                        <div className="col-2">                            
                            <label className="form-label"> Importe $:</label>
                            
                            <input 
                                type="number" 
                                name="valor"
                                maxLength="12"
                                className="form-control"
                                placeholder="Importe..."  
                                autoComplete="off"                               
                                onChange={ handleChange }
                                value={ valor }
                                required
                            />
                        </div>

                    </div>        
                        <button type="submit" className="btn btn-success mt-3 btn-add-cobranza"> 
                            {`AGREGAR  `} 
                            <i className="bi bi-box-arrow-down"></i>
                        </button>                      
                </form>                                
            </div>
        </div>
    )
}
