import axios from 'axios';
import config from '../config.json';

export const getRecibos = async ( idvendedor, tipoUsuario ) => {

    let aux = {};

    const url = `${config.SERVER_URL}/gets/getRecibosPorVendedor.php?idvendedor=${idvendedor}&empresa=${tipoUsuario}`;

    //console.log(url);

    await axios.get(url).then( res => {
        aux = res.data;
   } );

   return aux;

}
